<template>
    <div class="flex flex-col gap-[50px]">
        <div>
            <div class="flex flex-col gap-[8px] mb-[30px] pt-[5px]">
                <strong class="text-[16px]">Xác định đối tượng</strong>
                <template v-if="ads_account">
                <span class="text-[14px] text-[#6d6e70]">
                    {{ ads_account.name }} | {{ ads_account.currency }} ({{ ads_account.advertiser_id }})
                </span>
                </template>
            </div>
            <div>
                <div
                    class="border border-solid border-[#d3d4d5] rounded-[4px]"
                    style="border-bottom-left-radius: unset; border-bottom-right-radius: unset"
                >
                    <div class="h-[40px] bg-[#f8f8f9]">
                        <div class="flex gap-[10px] text-[15px] items-center h-[100%] pl-[20px]">
                            <i class="el-icon-user"></i>
                            Bao gồm
                        </div>
                    </div>
                </div>

                <!-- v-for here -->
                <div
                    v-for="(rule, index) in rule_spec.inclusions"
                    :key="index"
                    class="border border-solid border-[#d3d4d5]"
                    style="border-top: unset"
                >
                    <div class="min-h-[200px] pl-[20px] py-[30px]">
                        <div class="flex items-center">
                            <div class="flex-1" style="border-right: 1px solid #d3d4d5">
                                <div class="mt-[10px]">
                                    <div class="flex flex-col gap-[10px]">
                                        <strong class="text-[14px]">
                                            Những người thực hiện các hành động sau
                                        </strong>

                                        <div class="flex gap-[15px] items-center">
                                            <el-select
                                                v-model="rule.action_ids"
                                                class="w-[250px]"
                                                size="small"
                                                value=""
                                                multiple
                                                collapse-tags
                                                filterable
                                                clearable
                                            >
                                                <el-option
                                                    v-for="key in Object.keys(action_list)"
                                                    :key="key"
                                                    :value="key"
                                                    :label="action_list[key]"
                                                ></el-option>
                                            </el-select>
                                            <span class="text-[15px]">trong quá khứ</span>
                                            <el-select
                                                v-model="rule.retention"
                                                value=""
                                                size="small"
                                                placeholder="Vui lòng chọn"
                                                class="w-[120px]"
                                            >
                                                <el-option
                                                    :value="'7'"
                                                    :label="'7 ngày'"
                                                ></el-option>
                                                <el-option
                                                    :value="'14'"
                                                    :label="'14 ngày'"
                                                ></el-option>
                                                <el-option
                                                    :value="'30'"
                                                    :label="'30 ngày'"
                                                ></el-option>
                                                <el-option
                                                    :value="'60'"
                                                    :label="'60 ngày'"
                                                ></el-option>
                                                <el-option
                                                    :value="'90'"
                                                    :label="'90 ngày'"
                                                ></el-option>
                                                <el-option
                                                    :value="'180'"
                                                    :label="'180 ngày'"
                                                ></el-option>
                                            </el-select>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-[20px] flex flex-col gap-[10px]">
                                    <strong class="text-[14px]">
                                        Qua các pixel sau
                                    </strong>

                                    <el-select
                                        v-model="rule.pixel_ids"
                                        class="w-[250px]"
                                        value=""
                                        size="small"
                                        multiple
                                        collapse-tags
                                        filterable
                                        clearable
                                        placeholder="Tất cả"
                                    >
                                        <el-option
                                            v-for="pixel in pixel_list"
                                            :key="pixel.pixel_id"
                                            :value="pixel.pixel_id"
                                            :label="pixel.pixel_name"
                                        >
                                        </el-option>
                                    </el-select>
                                </div>

                                <div class="mt-[20px] flex flex-col gap-[10px]">
                                    <strong class="text-[14px]">
                                        URL(Không bắt buộc)
                                    </strong>
                                    <div>
                                        <el-input v-model="rule.url" size="small" class="w-[400px] fix-prepend">
                                            <div slot="prepend">
                                                <el-select
                                                    v-model="rule.url_match"
                                                    size="small"
                                                    value=""
                                                    class="w-[140px]"
                                                >
                                                    <el-option
                                                        :value="'CONTAINS'"
                                                        :label="'contains'"
                                                    ></el-option>
                                                    <el-option
                                                        :value="'DOES_NOT_CONTAIN'"
                                                        :label="'doesn\'t contains'"
                                                    ></el-option>
                                                    <el-option
                                                        :value="'EQ'"
                                                        :label="'equals'"
                                                    ></el-option>
                                                </el-select>
                                            </div>
                                        </el-input>
                                    </div>
                                </div>

                                <div class="mt-[20px] flex flex-col gap-[10px]">
                                    <strong class="text-[14px]">
                                        Thông số(Không bắt buộc)
                                    </strong>
                                    <div>
                                        <el-input v-model="rule.value" size="small" class="w-[600px] fix-prepend">
                                            <div slot="prepend" class="w-[300px]">
                                                <el-select
                                                    v-model="rule.key"
                                                    size="small"
                                                    value=""
                                                    class="w-[150px]"
                                                >
                                                    <el-option
                                                        :value="'CONTENT_TYPE'"
                                                        :label="'content_types'"
                                                    ></el-option>
                                                    <el-option
                                                        :value="'PRICE'"
                                                        :label="'price'"
                                                    ></el-option>
                                                    <el-option
                                                        :value="'VALUE'"
                                                        :label="'value'"
                                                    ></el-option>
                                                    <el-option
                                                        :value="'NUM_ITEM'"
                                                        :label="'num_item'"
                                                    ></el-option>
                                                    <el-option
                                                        :value="'CONTENT_ID'"
                                                        :label="'content_ids'"
                                                    ></el-option>
                                                    <el-option
                                                        :value="'CONTENT_CATEGORY'"
                                                        :label="'content_category'"
                                                    ></el-option>
                                                </el-select>
                                                <el-select
                                                    v-model="rule.operator"
                                                    size="small"
                                                    value=""
                                                    class="w-[150px]"
                                                >
                                                    <el-option
                                                        :value="'CONTAINS'"
                                                        :label="'contains'"
                                                    ></el-option>
                                                    <el-option
                                                        :value="'DOES_NOT_CONTAIN'"
                                                        :label="'doesn\'t contains'"
                                                    ></el-option>
                                                    <el-option
                                                        :value="'EQ'"
                                                        :label="'equals'"
                                                    ></el-option>
                                                    <el-option
                                                        :value="'IS_LESS_THAN'"
                                                        :label="'is less than'"
                                                    ></el-option>
                                                    <el-option
                                                        :value="'IS_GREATER_THAN'"
                                                        :label="'is greater than'"
                                                    ></el-option>
                                                    <el-option
                                                        :value="'IS_LESS_THAN_OR_EQUAL_TO'"
                                                        :label="'is less than or equal to'"
                                                    ></el-option>
                                                    <el-option
                                                        :value="'IS_GREATER_THAN_OR_EQUAL_TO'"
                                                        :label="'is greater than or equal to'"
                                                    ></el-option>
                                                </el-select>
                                            </div>
                                        </el-input>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="w-[300px] flex flex-col gap-[10px] pl-[30px]"
                            >
                                <el-dropdown trigger="click">
                                    <span>
                                        <i class="el-icon-plus"></i>
                                        Thêm quy tắc
                                        <i class="el-icon-arrow-down"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item @click.native="addIncludesRule()">
                                            Mở rộng phạm vi đối tượng
                                        </el-dropdown-item>
                                        <el-dropdown-item @click.native="addIncludesRule()">
                                            Thu hẹp phạm vi đối tượng
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>

                                <span
                                    v-if="rule_spec.inclusions.length > 1"
                                    class="text-[14px] cursor-pointer"
                                    @click="removeIncludesRule(index)"
                                >
                                    <i class="el-icon-delete"></i> Xoá
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <div class="flex flex-col gap-[8px] mb-[20px] pt-[5px]">
                <strong class="text-[16px]">Cài đặt đối tượng</strong>
            </div>
            <div>
                <div class="flex flex-col gap-[8px] mb-[30px] pt-[5px]">
                    <span class="text-[15px]">Tên đối tượng</span>
                    <el-input
                        v-model="custom_audience_name"
                        class="w-[350px]"
                        size="small"
                        placeholder="Nhập tên đối tượng"
                    ></el-input>
                </div>
                <div class="flex flex-col gap-[8px] mb-[30px] pt-[5px]">
                    <span class="text-[15px]">Tự động làm mới</span>
                    <div class="flex items-center gap-[10px]">
                        <el-switch
                            v-model="is_auto_refresh"
                            active-color="#13ce66"
                        >
                        </el-switch>
                        <span class="text-[14px] text-[#6d6e70]">Tự động làm mới đối tượng của bạn để xem dữ liệu người dùng mới nhất.</span>
                    </div>
                </div>
                <div class="flex flex-col gap-[8px] mb-[30px] pt-[5px]">
                    <span class="text-[15px]">Nâng cao đối tượng</span>
                    <div class="flex items-center gap-[10px]">
                        <el-switch
                            active-color="#13ce66"
                        >
                        </el-switch>
                        <span class="text-[14px] text-[#6d6e70]">Tăng tổng quy mô đối tượng được so khớp bằng cách thêm nhiều người dùng do mô hình máy học xác định hơn.</span>
                    </div>
                </div>
                <div class="flex flex-col gap-[8px] mb-[30px] pt-[5px]">
                    <span class="text-[15px]">Phạm vi tiếp cận và tần suất</span>
                    <div class="flex items-center gap-[10px]">
                        <el-switch
                            active-color="#13ce66"
                        >
                        </el-switch>
                        <span class="text-[14px] text-[#6d6e70]">
                            Tăng tổng quy mô đối tượng được so khớp bằng cách thêm nhiều người dùng do mô hình máy học xác định hơn.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createAudienceByRule } from '@/services/atosa-tiktok-ads/audience'
import { getPixels } from '@/services/atosa-tiktok-ads/event'

const default_rule = {
    action_ids: ['PAGE BROWSE'],
    retention: '30',
    pixel_id: null,
    pixel_ids: [],
    url: '',
    url_match: 'CONTAINS',
    key: 'CONTENT_TYPE',
    operator: 'CONTAINS',
    value: ''
}

export default {
    name: 'CreateAppActivityAudience',

    props: {
        ads_account: null,
        emit_create: null,
        create_loading: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            tiktok_account_id: undefined,
            custom_audience_name: '',
            audience_type: 'PIXEL',
            audience_sub_type: 'NORMAL',
            retention_in_days: undefined,
            is_auto_refresh: true,
            identity_id: undefined,
            identity_type: undefined,
            identity_authorized_bc_id: undefined,

            action_list: {
                'PAGE BROWSE': 'Xem trang',
                'CLICK BUTTON': 'Nhấp nút',
                'PIXEL SUBMIT FORM': 'Gửi biểu mẫu',
                'CONTACT': 'Liên hệ',
                'DOWNLOAD': 'Tải xuống',
                'PIXEL ADD PAYMENT INFO': 'Thêm thông tin thanh toán',
                'COMPLETE PAYMENT': 'Hoàn tất thanh toán',
                'INITIATE CHECKOUT': 'Lượt bắt đầu thanh toán',
                'COMPLETE REGISTRATION': 'Hoàn tất đăng ký',
                'PRODUCT DETAIL PAGE BROWSE': 'Xem nội dung',
                'PIXEL SEARCH': 'Tìm kiếm',
                'PIXEL ADD TO CART': 'Thêm vào giỏ hàng',
                'PLACE AN ORDER': 'Đặt đơn hàng',
                'PIXEL ADD TO WISHLIST': 'Thêm vào danh sách mong muốn',
                'PIXEL SUBSCRIBE': 'Đăng ký'
            },

            pixel_list: [],

            rule_spec: {
                inclusions: [JSON.parse(JSON.stringify(default_rule))],
                exclusions: []
            }
        }
    },

    watch: {
        emit_create: function() {
            if (this.emit_create) {
                this.confirmCreateAudience()
            }
        }
    },

    mounted() {
        this.tiktok_account_id = this.$store.getters.tiktokBusiness.s__selected_tiktok_business.id
        this.callGetPixels()
    },

    methods: {
        async callGetPixels() {
            const tiktok_account_id = this.$store.getters.tiktokBusiness.s__selected_tiktok_business.id
            const response = await getPixels(tiktok_account_id, { advertiser_id: this.ads_account.advertiser_id })
            this.pixel_list = response.data.pixels
        },

        addIncludesRule() {
            this.rule_spec.inclusions.push(JSON.parse(JSON.stringify(default_rule)))
        },

        removeIncludesRule(index) {
            this.rule_spec.inclusions.splice(index, 1)
        },

        async confirmCreateAudience() {
            if (!this.custom_audience_name) {
                this.$notify({
                    title: 'Thông báo',
                    message: 'Vui lòng nhập tên đối tượng',
                    type: 'warning'
                })
                return
            }

            this.$emit('update:create_loading', true)

            try {
                const form = {
                    advertiser_id: this.ads_account.advertiser_id,
                    custom_audience_name: this.custom_audience_name,
                    audience_type: this.audience_type,
                    audience_sub_type: this.audience_sub_type,
                    retention_in_days: this.retention_in_days,
                    is_auto_refresh: this.is_auto_refresh,
                    identity_id: this.identity_id,
                    identity_type: this.identity_type,
                    identity_authorized_bc_id: this.identity_authorized_bc_id,
                    rule_spec: {
                        inclusion_rule_set: {
                            operator: 'OR',
                            rules: []
                        },
                        exclusion_rule_set: {
                            operator: 'OR',
                            rules: []
                        }
                    }
                }

                for (const rule of this.rule_spec.inclusions) {
                    const row = {
                        event_source_ids: rule.pixel_ids,
                        retention_days: null,
                        filter_set: {
                            operator: 'OR',
                            filters: []
                        }
                    }
                    row.retention_days = +rule.retention
                    for (const action_id of rule.action_ids) {
                        const action = {
                            field: 'EVENT',
                            operator: 'EQ',
                            value: action_id,
                            parameter_filters: []
                        }

                        if (rule.url.trim()) {
                            const parameter_filter = {
                                field: 'URL',
                                operator: rule.url_match,
                                values: [rule.url]
                            }
                            action.parameter_filters.push(parameter_filter)
                        }
                        if (rule.value.trim()) {
                            const parameter_filter = {
                                field: rule.key,
                                operator: rule.operator,
                                values: [rule.value]
                            }
                            action.parameter_filters.push(parameter_filter)
                        }

                        row.filter_set.filters.push(action)
                    }
                    form.rule_spec.inclusion_rule_set.rules.push(row)
                }

                if (!form.rule_spec.exclusion_rule_set.rules.length) {
                    delete form.rule_spec.exclusion_rule_set
                }

                const response = await createAudienceByRule(this.tiktok_account_id, form)
                if (response.data) {
                    const {
                        code,
                        message
                    } = response.data
                    if (code === 0) {
                        this.$notify({
                            title: 'Thông báo',
                            message: 'Tạo đối tượng thành công',
                            type: 'success'
                        })
                        this.$emit('reload_audience')
                    } else {
                        this.$notify({
                            title: 'Lỗi',
                            message: 'Đã có lỗi xảy ra khi tạo đối tượng. Mã lỗi: ' + message,
                            type: 'warning'
                        })
                    }
                }
            } catch (e) {
                console.error(e)

                this.$notify({
                    title: 'Lỗi',
                    message: 'Đã có lỗi xảy ra',
                    type: 'warning'
                })
            }

            this.$emit('update:create_loading', false)
        }
    }
}
</script>

<style>
.fix-prepend .el-input-group__prepend {
    padding: 0 !important;
}

.fix-prepend .el-select {
    margin: -10px 0 !important;
}

.fix-prepend .el-input--suffix input {
    border: none !important;
}
</style>
