export function removeUnicode(str, toLowerCase = true) {
    const accentsMap = [
        {
            base: 'a',
            letters: /[áàảạãâấầẩậẫăắằẳẵặ]/g
        },
        {
            base: 'e',
            letters: /[éèẻẹẽêếềểệễ]/g
        },
        {
            base: 'i',
            letters: /[íìỉịĩ]/g
        },
        {
            base: 'o',
            letters: /[óòỏọõôốồổộỗơớờởợỡ]/g
        },
        {
            base: 'u',
            letters: /[úùủụũưứừửựữ]/g
        },
        {
            base: 'y',
            letters: /[ýỳỷỵỹ]/g
        },
        {
            base: 'd',
            letters: /[đ]/g
        },
        {
            base: 'A',
            letters: /[ÁÀẢẠÃÂẤẦẨẬẪĂẮẰẲẴẶ]/g
        },
        {
            base: 'E',
            letters: /[ÉÈẺẸẼÊẾỀỂỆỄ]/g
        },
        {
            base: 'I',
            letters: /[ÍÌỈỊĨ]/g
        },
        {
            base: 'O',
            letters: /[ÓÒỎỌÕÔỐỒỔỘỖƠỚỜỞỢỠ]/g
        },
        {
            base: 'U',
            letters: /[ÚÙỦỤŨƯỨỪỬỰỮ]/g
        },
        {
            base: 'Y',
            letters: /[ÝỲỶỴỸ]/g
        },
        {
            base: 'D',
            letters: /[Đ]/g
        }
    ]

    for (const accent of accentsMap) {
        str = str.replace(accent.letters, accent.base)
    }

    return toLowerCase ? str.toLowerCase() : str
}
