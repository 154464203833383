<template>
    <section>
        <div class="flex flex-col gap-[8px] mb-[30px] pt-[5px]">
            <span class="text-[16px]">Xác định đối tượng</span>
            <template v-if="ads_account">
                <span class="text-[14px] text-[#6d6e70]">
                    {{ ads_account.name }} | {{ ads_account.currency }} ({{ ads_account.advertiser_id }})
                </span>
            </template>
        </div>
        <div class="flex flex-col gap-[8px] mb-[30px] pt-[5px]">
            <span class="text-[16px]">Tải tệp lên(CSV hoặc TXT)</span>
            <span class="text-[14px] text-[#6d6e70]">
                Tải lên tệp CSV hoặc TXT gồm số điện thoại, email và số nhận dạng MAID dưới dạng các cột riêng biệt. Bổ sung nhiều số nhận dạng để cải thiện tỷ lệ so khớp và đảm bảo ID được hỗ trợ có định dạng đúng.
            </span>
            <el-upload
                drag
                action="#"
                :http-request="uploadFile"
            >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                    Kéo tệp của bạn vào đây hoặc nhấp để tải lên Dung lượng tệp của bạn không được vượt quá 1 GB.
                </div>
            </el-upload>
        </div>
        <div class="flex flex-col gap-[8px] mb-[30px] pt-[5px]">
            <span class="text-[15px]">Tên đối tượng</span>
            <el-input class="w-[350px]" size="small" placeholder="Nhập tên đối tượng"></el-input>
        </div>
        <div class="flex flex-col gap-[8px] mb-[30px] pt-[5px]">
            <span class="text-[15px]">Nâng cao đối tượng</span>
            <div class="flex items-center gap-[10px]">
                <el-switch
                    active-color="#13ce66"
                >
                </el-switch>
                <span class="text-[14px] text-[#6d6e70]">
                    Tăng tổng quy mô đối tượng được so khớp bằng cách thêm nhiều người dùng do mô hình máy học xác định hơn.
                </span>
            </div>
        </div>
        <div class="flex flex-col gap-[8px] mb-[30px] pt-[5px]">
            <span class="text-[15px]">Phạm vi tiếp cận và tần suất</span>
            <div class="flex items-center gap-[10px]">
                <el-switch
                    active-color="#13ce66"
                >
                </el-switch>
                <span class="text-[14px] text-[#6d6e70]">
                    Tăng tổng quy mô đối tượng được so khớp bằng cách thêm nhiều người dùng do mô hình máy học xác định hơn.
                </span>
            </div>
        </div>
    </section>
</template>

<script>
import { uploadAudienceFile } from '@/services/atosa-tiktok-ads/audience'

export default {
    name: 'CreateAudienceByFile',

    props: {
        ads_account: null,
        emit_create: null,
        create_loading: {
            type: Boolean,
            default: false
        }
    },

    mounted() {
    },

    methods: {
        async uploadFile(file) {
            if (!file || !file.file) return
            const tiktok_account_id = this.$store.getters.tiktokBusiness.s__selected_tiktok_business.id
            const response = await uploadAudienceFile(tiktok_account_id, file.file)
            console.log('r', response)
        }
    }
}
</script>
