<template>
    <div class="flex flex-col gap-[50px]">
        <div>
            <div class="flex flex-col gap-[8px] mb-[30px] pt-[5px]">
                <strong class="text-[16px]">Xác định đối tượng</strong>
                <template v-if="ads_account">
                <span class="text-[14px] text-[#6d6e70]">
                    {{ ads_account.name }} | {{ ads_account.currency }} ({{ ads_account.advertiser_id }})
                </span>
                </template>
            </div>
            <div>
                <div
                    class="border border-solid border-[#d3d4d5] rounded-[4px]"
                    style="border-bottom-left-radius: unset; border-bottom-right-radius: unset"
                >
                    <div class="h-[40px] bg-[#f8f8f9]">
                        <div class="flex gap-[10px] text-[15px] items-center h-[100%] pl-[20px]">
                            <i class="el-icon-user"></i>
                            Bao gồm
                        </div>
                    </div>
                </div>

                <!-- v-for here -->
                <div
                    v-for="(rule, index) in rule_spec.inclusions"
                    :key="index"
                    class="border border-solid border-[#d3d4d5]"
                    style="border-top: unset"
                >
                    <div class="min-h-[200px] pl-[20px] py-[30px]">
                        <div class="flex items-center">
                            <div class="flex-1" style="border-right: 1px solid #d3d4d5">
                                <div class="flex gap-[10px]">
                                    <strong class="text-[14px]">Chọn loại tài nguyên</strong>

                                    <el-dropdown trigger="click">
                                    <span>
                                        <template v-if="rule.asset_type === 'ad_group'">Nhóm quảng cáo</template>
                                        <template v-if="rule.asset_type === 'video'">Video</template>
                                        <i class="el-icon-arrow-down"></i>
                                    </span>
                                        <el-dropdown-menu slot="dropdown">
                                            <el-dropdown-item
                                                @click.native="rule.asset_type='ad_group'; rule.action = null"
                                            >
                                                Nhóm quảng cáo
                                            </el-dropdown-item>
                                            <el-dropdown-item
                                                @click.native="rule.asset_type='video'; rule.action = null"
                                            >
                                                Video
                                            </el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </div>
                                <!-- ad group -->
                                <div v-if="rule.asset_type === 'ad_group'" class="mt-[20px]">
                                    <div class="flex gap-[20px]">
                                        <div class="w-[300px] flex flex-col gap-[10px]">
                                            <strong class="text-[14px]">Nhóm quảng cáo</strong>
                                            <el-select
                                                v-model="rule.ad_group_ids"
                                                value=""
                                                size="small"
                                                multiple
                                                collapse-tags
                                                filterable
                                                clearable
                                                placeholder="Tất cả"
                                            >
                                                <el-option
                                                    v-for="ad_group in ad_group_list"
                                                    :key="ad_group.adgroup_id"
                                                    :value="ad_group.adgroup_id"
                                                    :label="ad_group.adgroup_name"
                                                >
                                                    <div class="flex flex-col gap-[5px]">
                                                        <span class="text-[14px]">
                                                            {{ ad_group.adgroup_name }}
                                                        </span>
                                                        <span class="text-[13px] text-[#999]">
                                                            ID: {{ ad_group.adgroup_id }}
                                                        </span>
                                                    </div>
                                                </el-option>
                                            </el-select>
                                        </div>
                                        <div class="w-[200px] flex flex-col gap-[10px]">
                                            <strong class="text-[14px]">Loại quảng cáo</strong>
                                            <el-select v-model="rule.ad_type" value="" size="small">
                                                <el-option
                                                    :value="1"
                                                    :label="'Video quảng cáo'"
                                                ></el-option>
                                                <el-option
                                                    :value="2"
                                                    :label="'Quảng cáo trang hồ sơ ứng dụng'"
                                                ></el-option>
                                                <el-option
                                                    :value="3"
                                                    :label="'Quảng cáo trang tức thì tùy chỉnh'"
                                                ></el-option>
                                                <el-option
                                                    :value="4"
                                                    :label="'Quảng cáo bộ sưu tập'"
                                                ></el-option>
                                            </el-select>
                                        </div>
                                    </div>
                                    <div class="mt-[20px] flex flex-col gap-[10px]">
                                        <strong class="text-[14px]">Hành động</strong>
                                        <div class="flex gap-[15px] items-center">
                                            <el-select
                                                v-model="rule.action"
                                                value=""
                                                size="small"
                                                placeholder="Vui lòng chọn"
                                                class="w-[205px]"
                                            >
                                                <el-option
                                                    :value="'CLICK'"
                                                    :label="'Nhấp'"
                                                ></el-option>
                                                <el-option
                                                    :value="'IMPRESSION'"
                                                    :label="'Hiển thị'"
                                                ></el-option>
                                                <el-option
                                                    :value="'PLAY 2S'"
                                                    :label="'Xem video trong 2 giây'"
                                                ></el-option>
                                                <el-option
                                                    :value="'PLAY 6S'"
                                                    :label="'Xem video trong 6 giây'"
                                                ></el-option>
                                                <el-option
                                                    :value="'PLAY 25'"
                                                    :label="'Xem 25% thời lượng video'"
                                                ></el-option>
                                                <el-option
                                                    :value="'PLAY 50'"
                                                    :label="'Xem 50% thời lượng video'"
                                                ></el-option>
                                                <el-option
                                                    :value="'PLAY 75'"
                                                    :label="'Xem 75% thời lượng video'"
                                                ></el-option>
                                                <el-option
                                                    :value="'PLAY OVER'"
                                                    :label="'Xem 100% thời lượng video'"
                                                ></el-option>
                                            </el-select>
                                            <span class="text-[15px]">trong quá khứ</span>
                                            <el-select
                                                v-model="rule.retention"
                                                value=""
                                                size="small"
                                                placeholder="Vui lòng chọn"
                                                class="w-[120px]"
                                            >
                                                <el-option
                                                    :value="'7'"
                                                    :label="'7 ngày'"
                                                ></el-option>
                                                <el-option
                                                    :value="'14'"
                                                    :label="'14 ngày'"
                                                ></el-option>
                                                <el-option
                                                    :value="'30'"
                                                    :label="'30 ngày'"
                                                ></el-option>
                                                <el-option
                                                    :value="'60'"
                                                    :label="'60 ngày'"
                                                ></el-option>
                                                <el-option
                                                    :value="'90'"
                                                    :label="'90 ngày'"
                                                ></el-option>
                                                <el-option
                                                    :value="'180'"
                                                    :label="'180 ngày'"
                                                ></el-option>
                                            </el-select>
                                        </div>
                                    </div>
                                </div>
                                <!-- video -->
                                <div v-if="rule.asset_type === 'video'" class="mt-[20px] flex flex-col gap-[20px]">
                                    <div class="flex gap-[20px]">
                                        <div class="w-[200px] flex flex-col gap-[10px]">
                                            <strong class="text-[14px]">Loại video</strong>
                                            <el-select
                                                v-model="rule.video_type"
                                                value=""
                                                size="small"
                                                @change="rule.tiktok_account_id = null; rule.video_id = null; rule.action = null"
                                            >
                                                <el-option
                                                    :value="'video'"
                                                    :label="'Video công khai'"
                                                ></el-option>
                                                <el-option
                                                    :value="'live'"
                                                    :label="'Video LIVE'"
                                                ></el-option>
                                            </el-select>
                                        </div>
                                        <div class="w-[300px] flex flex-col gap-[10px]">
                                            <strong class="text-[14px]">Tài khoản tiktok</strong>
                                            <el-select
                                                v-model="rule.tiktok_account_id"
                                                value=""
                                                size="small"
                                                filterable
                                                @change="rule.video_id = null; callGetVideoFromIdentity(rule.video_type, rule.tiktok_account_id)"
                                            >
                                                <el-option
                                                    v-for="item in tiktok_account_list"
                                                    :key="item.identity_id"
                                                    :value="item.identity_id"
                                                    :label="item.display_name"
                                                >
                                                    <div class="flex gap-[10px] py-[10px] items-center">
                                                        <div class="w-[35px] h-[35px]">
                                                            <img
                                                                class="w-[100%] h-[100%]"
                                                                :src="item.profile_image"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div class="flex-1">
                                                            {{ item.display_name }}
                                                        </div>
                                                    </div>
                                                </el-option>
                                            </el-select>
                                        </div>
                                    </div>
                                    <div class="flex gap-[20px]">
                                        <div class="w-[300px] flex flex-col gap-[10px]">
                                            <strong class="text-[14px]">Video/Phòng LIVE</strong>
                                            <el-select
                                                v-model="rule.video_id"
                                                value=""
                                                size="small"
                                                filterable
                                                clearable
                                            >
                                                <el-option
                                                    v-for="video in video_list"
                                                    :key="video.item_id || video.live_id"
                                                    :value="video.item_id || video.live_id"
                                                    :label="video.item_id || video.live_id"
                                                >
                                                    <div v-if="video.type === 'video'">
                                                        <div class="flex flex-col gap-[5px]">
                                                        <span class="text-[14px]">
                                                            {{ video.text }}
                                                        </span>
                                                            <span class="text-[13px] text-[#999]">
                                                            ID: {{ video.item_id }}
                                                        </span>
                                                        </div>
                                                    </div>
                                                    <div v-if="video.type === 'live'">
                                                        <div class="flex flex-col gap-[5px]">
                                                        <span class="text-[14px]">
                                                            {{ video.live_id }}
                                                        </span>
                                                            <span class="text-[13px] text-[#999]">
                                                            Hoàn tất vào: {{ video.finish_timestamp }}
                                                        </span>
                                                        </div>
                                                    </div>
                                                </el-option>
                                            </el-select>
                                        </div>
                                        <div class="w-[200px] flex flex-col gap-[10px]">
                                            <strong class="text-[14px]">Hành động</strong>
                                            <el-select v-model="rule.action" value="" size="small">
                                                <template v-if="rule.video_type === 'video'">
                                                    <el-option
                                                        :value="'PLAY 2S'"
                                                        :label="'Xem video trong 2 giây'"
                                                    ></el-option>
                                                    <el-option
                                                        :value="'PLAY 6S'"
                                                        :label="'Xem video trong 6 giây'"
                                                    ></el-option>
                                                    <el-option
                                                        :value="'PLAY OVER'"
                                                        :label="'Xem 100% thời lượng video'"
                                                    ></el-option>
                                                    <el-option
                                                        :value="'Organic Engagement Audience'"
                                                        :label="'Tương tác'"
                                                    ></el-option>
                                                </template>
                                                <template v-if="rule.video_type === 'live'">
                                                    <el-option
                                                        :value="'LIVE VIDEO VIEW'"
                                                        :label="'Xem'"
                                                    ></el-option>
                                                    <el-option
                                                        :value="'LIVE VIDEO ENGAGEMENT'"
                                                        :label="'Tương tác'"
                                                    ></el-option>
                                                </template>
                                            </el-select>
                                        </div>
                                    </div>
                                    <div class="flex gap-[20px] items-center">
                                        <span class="text-[14px]">trong quá khứ</span>
                                        <el-select
                                            v-model="rule.retention"
                                            value=""
                                            size="small"
                                            placeholder="Vui lòng chọn"
                                            class="w-[120px]"
                                        >
                                            <el-option
                                                :value="'7'"
                                                :label="'7 ngày'"
                                            ></el-option>
                                            <el-option
                                                :value="'14'"
                                                :label="'14 ngày'"
                                            ></el-option>
                                            <el-option
                                                :value="'30'"
                                                :label="'30 ngày'"
                                            ></el-option>
                                            <el-option
                                                :value="'60'"
                                                :label="'60 ngày'"
                                            ></el-option>
                                            <el-option
                                                :value="'90'"
                                                :label="'90 ngày'"
                                            ></el-option>
                                            <el-option
                                                :value="'180'"
                                                :label="'180 ngày'"
                                            ></el-option>
                                        </el-select>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="w-[300px] flex flex-col gap-[10px] pl-[30px]"
                            >
                                <el-dropdown trigger="click">
                                    <span>
                                        <i class="el-icon-plus"></i>
                                        Thêm quy tắc
                                        <i class="el-icon-arrow-down"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item @click.native="addIncludesRule()">
                                            Mở rộng phạm vi đối tượng
                                        </el-dropdown-item>
                                        <el-dropdown-item @click.native="addIncludesRule()">
                                            Thu hẹp phạm vi đối tượng
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>

                                <span
                                    v-if="rule_spec.inclusions.length > 1"
                                    class="text-[14px] cursor-pointer"
                                    @click="removeIncludesRule(index)"
                                >
                                    <i class="el-icon-delete"></i> Xoá
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <div class="flex flex-col gap-[8px] mb-[20px] pt-[5px]">
                <strong class="text-[16px]">Cài đặt đối tượng</strong>
            </div>
            <div>
                <div class="flex flex-col gap-[8px] mb-[30px] pt-[5px]">
                    <span class="text-[15px]">Tên đối tượng</span>
                    <el-input
                        v-model="custom_audience_name"
                        class="w-[350px]"
                        size="small"
                        placeholder="Nhập tên đối tượng"
                    ></el-input>
                </div>
                <div class="flex flex-col gap-[8px] mb-[30px] pt-[5px]">
                    <span class="text-[15px]">Tự động làm mới</span>
                    <div class="flex items-center gap-[10px]">
                        <el-switch
                            v-model="is_auto_refresh"
                            active-color="#13ce66"
                        >
                        </el-switch>
                        <span class="text-[14px] text-[#6d6e70]">Tự động làm mới đối tượng của bạn để xem dữ liệu người dùng mới nhất.</span>
                    </div>
                </div>
                <div class="flex flex-col gap-[8px] mb-[30px] pt-[5px]">
                    <span class="text-[15px]">Nâng cao đối tượng</span>
                    <div class="flex items-center gap-[10px]">
                        <el-switch
                            active-color="#13ce66"
                        >
                        </el-switch>
                        <span class="text-[14px] text-[#6d6e70]">Tăng tổng quy mô đối tượng được so khớp bằng cách thêm nhiều người dùng do mô hình máy học xác định hơn.</span>
                    </div>
                </div>
                <div class="flex flex-col gap-[8px] mb-[30px] pt-[5px]">
                    <span class="text-[15px]">Phạm vi tiếp cận và tần suất</span>
                    <div class="flex items-center gap-[10px]">
                        <el-switch
                            active-color="#13ce66"
                        >
                        </el-switch>
                        <span class="text-[14px] text-[#6d6e70]">
                    Tăng tổng quy mô đối tượng được so khớp bằng cách thêm nhiều người dùng do mô hình máy học xác định hơn.
                </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getAdGroups } from '@/services/atosa-tiktok-ads/adgroup'
import { getIdentities, getVideoFromIdentity, getVideoOrLiveFromIdentity } from '@/services/atosa-tiktok-ads/identity'
import { createAudienceByRule } from '@/services/atosa-tiktok-ads/audience'

export default {
    name: 'CreateEngagementAudience',

    props: {
        ads_account: null,
        emit_create: null,
        create_loading: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            tiktok_account_id: undefined,
            custom_audience_name: '',
            audience_type: 'ENGAGEMENT',
            audience_sub_type: 'NORMAL',
            retention_in_days: undefined,
            is_auto_refresh: true,
            identity_id: undefined,
            identity_type: undefined,
            identity_authorized_bc_id: undefined,

            ad_group_list: [],
            tiktok_account_list: [],
            video_list: [],

            rule_spec: {
                inclusions: [
                    {
                        asset_type: 'ad_group',
                        ad_group_id: null,
                        ad_group_ids: null,
                        ad_type: 1,
                        action: null,
                        video_type: 'video',
                        tiktok_account_id: null,
                        video_id: null,
                        retention: '7'
                    }
                ],
                exclusions: []
            }
        }
    },

    watch: {
        emit_create: function() {
            if (this.emit_create) {
                this.confirmCreateAudience()
            }
        }
    },

    mounted() {
        this.tiktok_account_id = this.$store.getters.tiktokBusiness.s__selected_tiktok_business.id
        this.callGetAdGroup()
        this.callGetIdentities()
    },

    methods: {
        async callGetIdentities() {
            const response = await getIdentities(this.tiktok_account_id, {
                advertiser_id: this.ads_account.advertiser_id,
                belong_to_atosa: false,
                identity_type: 'BC_AUTH_TT'
            })
            this.tiktok_account_list = response.data.identity_list
        },

        async callGetVideoFromIdentity(type, identity_id) {
            const identity = this.tiktok_account_list.find(item => item.identity_id === identity_id)
            if (!identity) return

            this.video_list = []

            let response = await getVideoFromIdentity(
                this.tiktok_account_id,
                {
                    belong_to_atosa: false,
                    advertiser_id: this.ads_account.advertiser_id,
                    identity_id: identity.identity_id,
                    identity_authorized_bc_id: identity.identity_authorized_bc_id,
                    identity_type: 'BC_AUTH_TT',
                    cursor: '0'
                }
            )
            while (response && response.data && response.data.has_more) {
                if (response.data[`${type}_list`]) {
                    for (const item of response.data[`${type}_list`]) {
                        item.type = type
                    }
                    this.video_list.push(...response.data[`${type}_list`])
                }

                response = await getVideoOrLiveFromIdentity(
                    this.tiktok_account_id,
                    type,
                    {
                        belong_to_atosa: false,
                        advertiser_id: this.ads_account.advertiser_id,
                        identity_id: identity.identity_id,
                        identity_authorized_bc_id: identity.identity_authorized_bc_id,
                        identity_type: 'BC_AUTH_TT',
                        cursor: response.data.cursor
                    }
                )
            }

            console.log('v', this.video_list)
        },

        async callGetAdGroup() {
            let page = 1
            let response = await getAdGroups(this.tiktok_account_id, {
                advertiser_id: this.ads_account.advertiser_id,
                page: page,
                page_size: 100
            })
            while (response && response.data && response.data.list && response.data.list.length) {
                this.ad_group_list.push(...response.data.list)

                page++
                response = await getAdGroups(this.tiktok_account_id, {
                    advertiser_id: this.ads_account.advertiser_id,
                    page: page,
                    page_size: 100
                })
            }
        },

        addIncludesRule() {
            this.rule_spec.inclusions.push({
                asset_type: 'ad_group',
                ad_group_id: null,
                ad_type: 1,
                action: null,
                video_type: 'video',
                tiktok_account_id: null,
                video_id: null,
                retention: '7'
            })
        },

        removeIncludesRule(index) {
            this.rule_spec.inclusions.splice(index, 1)
        },

        async confirmCreateAudience() {
            if (!this.custom_audience_name) {
                this.$notify({
                    title: 'Thông báo',
                    message: 'Vui lòng nhập tên đối tượng',
                    type: 'warning'
                })
                return
            }

            this.$emit('update:create_loading', true)

            try {
                const form = {
                    advertiser_id: this.ads_account.advertiser_id,
                    custom_audience_name: this.custom_audience_name,
                    audience_type: this.audience_type,
                    audience_sub_type: this.audience_sub_type,
                    retention_in_days: this.retention_in_days,
                    is_auto_refresh: this.is_auto_refresh,
                    identity_id: this.identity_id,
                    identity_type: this.identity_type,
                    identity_authorized_bc_id: this.identity_authorized_bc_id,
                    rule_spec: {
                        inclusion_rule_set: {
                            operator: 'OR',
                            rules: []
                        },
                        exclusion_rule_set: {
                            operator: 'OR',
                            rules: []
                        }
                    }
                }

                for (const rule of this.rule_spec.inclusions) {
                    const row = {
                        event_source_ids: [],
                        retention_days: null,
                        filter_set: {
                            operator: 'OR',
                            filters: []
                        }
                    }

                    if (rule.asset_type === 'ad_group') {
                        row.event_source_ids = rule.ad_group_ids
                        row.retention_days = +rule.retention
                        row.filter_set.filters.push({
                            field: 'EVENT',
                            operator: 'EQ',
                            value: rule.action
                        })
                    }

                    if (rule.asset_type === 'video') {
                        if (rule.video_type === 'video') form.audience_type = 'ENGAGEMENT_ORGANIC_VIDEO'
                        if (rule.video_type === 'live') form.audience_type = 'ENGAGEMENT_LIVE_VIDEO'

                        const identity = this.tiktok_account_list.find(item => item.identity_id === rule.tiktok_account_id)
                        form.identity_id = identity.identity_id
                        form.identity_type = identity.identity_type
                        form.identity_authorized_bc_id = identity.identity_authorized_bc_id

                        row.event_source_ids = [rule.video_id]
                        row.retention_days = +rule.retention
                        row.filter_set.filters.push({
                            field: 'EVENT',
                            operator: 'EQ',
                            value: rule.action
                        })
                    }
                    form.rule_spec.inclusion_rule_set.rules.push(row)
                }

                if (!form.rule_spec.exclusion_rule_set.rules.length) {
                    delete form.rule_spec.exclusion_rule_set
                }

                console.log(form)

                const response = await createAudienceByRule(this.tiktok_account_id, form)
                if (response.data) {
                    const {
                        code,
                        message
                    } = response.data
                    if (code === 0) {
                        this.$notify({
                            title: 'Thông báo',
                            message: 'Tạo đối tượng thành công',
                            type: 'success'
                        })
                        this.$emit('reload_audience')
                    } else {
                        this.$notify({
                            title: 'Lỗi',
                            message: 'Đã có lỗi xảy ra khi tạo đối tượng. Mã lỗi: ' + message,
                            type: 'warning'
                        })
                    }
                }
            } catch (e) {
                console.error(e)

                this.$notify({
                    title: 'Lỗi',
                    message: 'Đã có lỗi xảy ra',
                    type: 'warning'
                })
            }

            this.$emit('update:create_loading', false)
        }
    }
}
</script>
