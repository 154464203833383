<template>
    <div>
        <div class="flex flex-col gap-[8px] mb-[30px] pt-[5px]">
            <span class="text-[16px]">Xác định đối tượng</span>
            <template v-if="ads_account">
                <span class="text-[14px] text-[#6d6e70]">
                    {{ ads_account.name }} | {{ ads_account.currency }} ({{ ads_account.advertiser_id }})
                </span>
            </template>
        </div>
        <div class="flex flex-col gap-[8px] mb-[20px] pt-[5px]">
            <strong class="text-[14px]">Nguồn</strong>

            <template v-if="audience_list">
                <el-select
                    v-model="source_audience_id"
                    size="small"
                    value=""
                    class="w-[300px]"
                    placeholder="Chọn đối tượng tùy chỉnh"
                    :disabled="!!from_source_id"
                >
                    <el-option
                        v-for="audience in audience_list.filter(i => i.is_creator)"
                        :key="audience.audience_id"
                        :value="audience.audience_id"
                        :label="audience.name"
                    ></el-option>
                </el-select>
            </template>
        </div>
        <div class="flex flex-col gap-[8px] mb-[20px] pt-[5px]">
            <strong class="text-[14px]">Chứa nguồn</strong>

            <el-radio-group v-model="include_source" size="small">
                <el-radio-button label="false">Bỏ qua</el-radio-button>
                <el-radio-button label="true">Chứa</el-radio-button>
            </el-radio-group>
        </div>
        <div class="flex flex-col gap-[8px] mb-[20px] pt-[5px]">
            <strong class="text-[14px]">Hệ thống</strong>

            <el-radio-group v-model="mobile_os" size="small">
                <el-radio-button label="ALL">Tất cả</el-radio-button>
                <el-radio-button label="ANDROID">Android</el-radio-button>
                <el-radio-button label="IOS">iOS</el-radio-button>
            </el-radio-group>
        </div>
        <div class="flex flex-col gap-[8px] mb-[20px] pt-[5px]">
            <strong class="text-[14px]">Vị trí</strong>

            <el-select
                v-model="placements"
                size="small"
                value=""
                class="w-[300px]"
                placeholder="Chọn vị trí"
                multiple
                collapse-tags
            >
                <el-option :value="'TikTok'" :label="'Tiktok'"></el-option>
                <el-option :value="'Pangle'" :label="'Pangle'"></el-option>
            </el-select>
        </div>
        <div class="flex flex-col gap-[8px] mb-[20px] pt-[5px]">
            <strong class="text-[14px]">Địa điểm</strong>

            <el-select
                v-model="location_ids"
                size="small"
                value=""
                class="w-[300px]"
                placeholder="Chọn vị trí"
                multiple
                collapse-tags
                filterable
                clearable
            >
                <el-option
                    v-for="country in country_list"
                    :key="country.code"
                    :value="country.code"
                    :label="country.name"
                >
                </el-option>
            </el-select>
        </div>
        <div class="flex flex-col gap-[8px] mb-[20px] pt-[5px]">
            <strong class="text-[14px]">Quy mô đối tượng</strong>

            <el-radio-group v-model="audience_size" size="small">
                <el-radio-button label="NARROW">Hẹp</el-radio-button>
                <el-radio-button label="BALANCED">Cân bằng</el-radio-button>
                <el-radio-button label="BROAD">Mở rộng</el-radio-button>
            </el-radio-group>
        </div>
        <div class="flex flex-col gap-[8px] mb-[20px] pt-[5px]">
            <strong class="text-[14px]">Tên đối tượng</strong>

            <el-input
                v-model="custom_audience_name"
                size="small"
                class="w-[300px]"
                placeholder="Nhập tên đối tượng"
            ></el-input>
        </div>
    </div>
</template>

<script>
import { country_list } from '@/views/credit-card/billing-address/country-list'
import { createLookalikeAudience } from '@/services/atosa-tiktok-ads/audience'

export default {
    props: {
        ads_account: null,
        emit_create: null,
        create_loading: {
            type: Boolean,
            default: false
        },
        audience_list: null,
        from_source_id: null
    },

    data() {
        return {
            country_list,

            tiktok_account_id: undefined,
            custom_audience_name: '',
            source_audience_id: null,
            include_source: 'false',
            mobile_os: 'ALL',
            placements: [],
            location_ids: [],
            audience_size: 'BALANCED'
        }
    },

    watch: {
        emit_create: function() {
            if (this.emit_create) {
                this.confirmCreateAudience()
            }
        }
    },

    mounted() {
        this.tiktok_account_id = this.$store.getters.tiktokBusiness.s__selected_tiktok_business.id
        this.source_audience_id = this.from_source_id
    },

    methods: {
        async confirmCreateAudience() {
            if (!this.custom_audience_name) {
                this.$notify({
                    title: 'Thông báo',
                    message: 'Vui lòng nhập tên đối tượng',
                    type: 'warning'
                })
                return
            }

            this.$emit('update:create_loading', true)

            try {
                const form = {
                    advertiser_id: this.ads_account.advertiser_id,
                    custom_audience_name: this.custom_audience_name,
                    lookalike_spec: {
                        source_audience_id: this.source_audience_id,
                        include_source: this.include_source === 'true',
                        mobile_os: this.mobile_os,
                        placements: this.placements,
                        location_ids: this.location_ids,
                        audience_size: this.audience_size
                    }
                }

                const response = await createLookalikeAudience(this.tiktok_account_id, form)
                if (response.data) {
                    const {
                        code,
                        message
                    } = response.data
                    if (code === 0) {
                        this.$notify({
                            title: 'Thông báo',
                            message: 'Tạo đối tượng thành công',
                            type: 'success'
                        })
                        this.$emit('reload_audience')
                    } else {
                        this.$notify({
                            title: 'Lỗi',
                            message: 'Đã có lỗi xảy ra khi tạo đối tượng. Mã lỗi: ' + message,
                            type: 'warning'
                        })
                    }
                }
            } catch (e) {
                console.error(e)

                this.$notify({
                    title: 'Lỗi',
                    message: 'Đã có lỗi xảy ra',
                    type: 'warning'
                })
            }

            this.$emit('update:create_loading', false)
        }
    }
}
</script>
