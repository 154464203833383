<template>
    <div class="mt-[30px] px-[5px]">
        <div>
            <div class="flex gap-[10px]">
                <div class="text-[18px] text-[#000]">
                    {{ audience.name }}
                </div>
                <div>
                    <el-popover
                        :ref="`popover-name-detail`"
                        placement="right"
                        width="400"
                        trigger="click"
                        @click.native="edit_name = audience.name"
                    >
                        <div class="flex flex-col gap-[5px] p-[10px]">
                            <div>
                                <strong class="text-[14px]">
                                    Tên đối tượng
                                </strong>
                            </div>
                            <div class="flex">
                                <el-input
                                    v-model="edit_name"
                                    size="small"
                                    class="flex-1"
                                ></el-input>
                                <el-button
                                    size="small"
                                    icon="el-icon-close"
                                    class="ml-[10px]"
                                    @click="$refs['popover-name-detail'].doClose()"
                                ></el-button>
                                <el-button
                                    type="primary"
                                    size="small"
                                    icon="el-icon-check"
                                    :loading="loading.edit_name"
                                    @click="confirmEditName(audience_detail)"
                                ></el-button>
                            </div>
                        </div>
                        <i slot="reference" class="el-icon-edit text-[14px] cursor-pointer"></i>
                    </el-popover>
                </div>
            </div>
            <div class="mt-[5px] flex gap-[40px]">
                <div class="text-[14px] text-[#6d6e70]">
                    ID: {{ audience_detail.audience_id }}
                </div>
                <div class="text-[14px] text-[#6d6e70]">
                    Loại:
                    {{ mapAudienceType(audience) }}
                    {{ mapAudienceEnum(audience) ? ` - ${mapAudienceEnum(audience)}` : '' }}
                </div>
                <div class="text-[14px] text-[#6d6e70]">
                    Tự động làm mới: {{ audience_detail.is_auto_refresh ? 'Bật' : 'Tắt' }}
                </div>
                <div class="text-[14px] text-[#6d6e70]">
                    Phạm vi tiếp cận và tần suất: {{ audience_detail.enhancement_status === 'ON' ? 'Bật' : 'Tắt' }}
                </div>
            </div>
        </div>

        <div class="mt-[50px]">
            <el-tabs>
                <el-tab-pane label="Thông tin ứng dụng">
                    <el-table :data="apply_logs">
                        <el-table-column label="Mã nhóm quảng cáo">
                            <template slot-scope="scope">
                                {{ scope.row.adgroup_id }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Nhóm quảng cáo đối tượng">
                            <template slot-scope="scope">
                                {{ scope.row.adgroup_name }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Trình biên tập">
                            <template slot-scope="scope">
                                {{ scope.row.editor }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Thay đổi vào">
                            <template slot-scope="scope">
                                {{ scope.row.action_timestamp }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Chế độ sử dụng">
                            <template slot-scope="scope">
                                <template v-if="scope.row.usage_mode === 'Include'">Bao gồm</template>
                                <template v-else-if="scope.row.usage_mode === 'Exclude'">Ngoại trừ</template>
                                <template v-else>{{ scope.row.usage_mode }}</template>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="Chi tiết chia sẻ">
                    <el-table :data="share_logs">
                        <el-table-column label="Trạng thái">
                            <template slot-scope="scope">
                                {{ scope.row.status === 'VALID' ? 'Chia sẻ' : 'Dừng chia sẻ' }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Nguời nhận">
                            <template slot-scope="scope">
                                {{ scope.row.shared_advertiser_name }}
                            </template>
                        </el-table-column>
                        <el-table-column label="ID nguời nhận">
                            <template slot-scope="scope">
                                {{ scope.row.shared_advertiser_id }}
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="Lịch sử đối tượng">
                    <!--                    <pre>{{ audience_history }}</pre>-->

                    <el-table :data="audience_history">
                        <el-table-column label="Hành động">
                            <template slot-scope="scope">
                                <template v-if="scope.row.action.includes('create')">
                                    Tạo đối tượng
                                </template>
                                <template v-else-if="scope.row.action.includes('auto refresh')">
                                    Làm mới đối tượng
                                </template>
                            </template>
                        </el-table-column>
                        <el-table-column label="Chi tiết">
                            <template slot-scope="scope">
                                <template v-if="scope.row.action.includes('create')">
                                    Đã tạo đối tượng
                                </template>
                                <template v-else-if="scope.row.action.includes('auto refresh')">
                                    Tổng số người dùng phù hợp:
                                    {{ p__formatCurrency(scope.row.action_detail) }}
                                </template>
                            </template>
                        </el-table-column>
                        <el-table-column lable="Tự động làm mới">
                            <template>
                                Tự động làm mới
                            </template>
                        </el-table-column>
                        <el-table-column lable="Thay đổi vào">
                            <template slot-scope="scope">
                                {{ scope.row.opt_time }}
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import {
    getApplicationLogAudience,
    getAudienceDetails,
    getSharingLogAudience,
    updateAudience
} from '@/services/atosa-tiktok-ads/audience'

export default {
    props: {
        ads_account: null,
        audience: null
    },

    data() {
        return {
            loading: {
                edit_name: false
            },
            edit_name: '',
            audience_detail: {},
            audience_history: [],
            apply_logs: [],
            share_logs: []
        }
    },

    watch: {
        'audience': function() {
            // console.log(this.ads_account)
            // console.log(this.audience)
        }
    },

    async mounted() {
        // console.log(this.ads_account)
        // console.log(this.audience)

        await this.callGetAudienceDetail()
        await this.callGetApplicationLogAudience()
    },

    methods: {
        async confirmEditName(audience) {
            if (!this.edit_name) return

            this.loading.edit_name = true
            try {
                const form = {
                    advertiser_id: this.ads_account.advertiser_id,
                    custom_audience_id: audience.audience_id
                }
                form.custom_audience_name = this.edit_name

                const response = await updateAudience(this.$store.getters.tiktokBusiness.s__selected_tiktok_business.id, form)
                const {
                    code,
                    message
                } = response.data
                if (code === 0) {
                    this.$notify({
                        title: 'Thông báo',
                        message: 'Cập nhật đối tượng thành công',
                        type: 'success'
                    })
                    this.$refs['popover-name-detail'].doClose()
                } else {
                    this.$notify({
                        title: 'Lỗi',
                        message: 'Đã có lỗi xảy ra khi cập nhật đối tượng. Mã lỗi: ' + message,
                        type: 'warning'
                    })
                }
            } catch (e) {
                console.error(e)
            }
            this.loading.edit_name = false
        },

        mapAudienceType(audience) {
            if (audience.audience_type === 'Lookalike Audience') {
                return 'Đối tượng tương tự' + (audience.is_creator ? '' : ' Được chia sẻ')
            }

            return 'Đối tượng tùy chỉnh' + (audience.is_creator ? '' : ' Được chia sẻ')
        },

        mapAudienceEnum(audience) {
            if (audience.audience_type === 'Customer File Audience') {
                return 'Tệp khách hàng'
            }
            if (audience.audience_type === 'Engagement Audience') {
                return 'Sự tương tác'
            }
            if (audience.audience_type === 'App Activity Audience') {
                return 'Hoạt động trong ứng dụng'
            }
            if (audience.audience_type === 'Website Audience') {
                return 'Lưu lượng truy cập trang web'
            }
            if (audience.audience_type === 'Lead Generation Audience') {
                return 'Tạo khách hàng tiềm năng'
            }
            if (audience.audience_type === 'Rule Business Account') {
                return 'Business Account'
            }
            if (audience.audience_type === 'Rule ad shop') {
                return 'Hoạt động trong cửa hàng'
            }
            if (audience.audience_type === 'Rule Offline Events') {
                return 'Hoạt động ngoại tuyến'
            }

            return ''
        },

        async callGetAudienceDetail() {
            if (!this.audience || !this.audience.audience_id) {
                return
            }

            const tiktok_account_id = this.$store.getters.tiktokBusiness.s__selected_tiktok_business.id
            const response = await getAudienceDetails(tiktok_account_id, {
                advertiser_id: this.ads_account.advertiser_id,
                custom_audience_ids: `["${this.audience.audience_id}"]`
            })

            if (response && response.data && response.data.list && response.data.list.length) {
                this.audience_detail = response.data.list[0].audience_details
                this.audience_history = response.data.list[0].audience_history
            }
            console.log(this.audience_detail)
            console.log(this.audience_history)
        },

        async callGetApplicationLogAudience() {
            if (!this.audience || !this.audience.audience_id) {
                return
            }

            const tiktok_account_id = this.$store.getters.tiktokBusiness.s__selected_tiktok_business.id
            const apply_log_response = await getApplicationLogAudience(tiktok_account_id, {
                advertiser_id: this.ads_account.advertiser_id,
                custom_audience_ids: `["${this.audience.audience_id}"]`
            })
            if (apply_log_response && apply_log_response.data && apply_log_response.data.list) {
                this.apply_logs = apply_log_response.data.list
            }

            const share_log_response = await getSharingLogAudience(tiktok_account_id, {
                advertiser_id: this.ads_account.advertiser_id,
                custom_audience_id: this.audience.audience_id
            })
            console.log(share_log_response)
            if (share_log_response && share_log_response.data && share_log_response.data.list) {
                this.share_logs = share_log_response.data.list
            }
        }
    }
}
</script>
